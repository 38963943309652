import React from "react";


class Settings extends React.Component {
  render() {
    return (
        <div>
            <h1>Settings.js component</h1>
        </div>
    );
  }
}

export default Settings;