import React, { useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { getSlideritems } from "../../constants/AdminApis"

export default function PrivateRoutes() {
  const userProfile = JSON.parse(localStorage.getItem('logindata'));
  return (
    <>
      {userProfile == "" || userProfile == null  ? <Navigate to='/' /> : <Outlet />}
    </>
  )
}