import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from '../../../bn-logo.jpg'

import {
  faBriefcase,
  faTimes,
  faGauge,
  faCartShopping,
  faGear,
  faSliders,
  faLayerGroup,
  faComment
} from "@fortawesome/free-solid-svg-icons";
import { Nav, Button } from "react-bootstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";
import {logoutapi} from "../../constants/AdminApis";

class SideBar extends React.Component {
   logout = async() => {
    logoutapi()
    localStorage.clear();
    window.location.href= "/";
  }
  render() {
    return (
      <div className={classNames("sidebar", { "is-open": this.props.isOpen })}>
        <div className="sidebar-header">
          <Button
            variant="link"
            onClick={this.props.toggle}
            style={{ color: "#fff" }}
            className="mt-4"
          >
            <FontAwesomeIcon icon={faTimes} pull="right" size="xs" />
          </Button>
          <div className="sidebar-logo-wrapper">
            <h3>Bigshot Nutrition</h3>
            {/* <img src={logo} className="sidebar-logo " alt="logo" /> */}
          </div>
        </div>

        <Nav className="flex-column pt-2 list-group">
          <Nav.Item className="nav-item nav-item">
            <Link to='/admin' className="link-item">
              <Nav className="nav-item" onClick={this.props.device == true ? this.props.toggle : () => { }}>
                <FontAwesomeIcon icon={faGauge} className="mx-2 pt-1" />
                Dashboard
              </Nav>
            </Link>
            <Link to='products' className="link-item">
              <Nav className="nav-item" onClick={this.props.device == true ? this.props.toggle : () => { }}>
                <FontAwesomeIcon icon={faCartShopping} className="mx-2 pt-1" />
                Products
              </Nav>
            </Link>
            <Link to='categories' className="link-item">
              <Nav className="nav-item" onClick={this.props.device == true ? this.props.toggle : () => { }}>
                <FontAwesomeIcon icon={faLayerGroup} className="mx-2 pt-1" />
                 Categories
              </Nav>
            </Link>
            <Link to='slider' className="link-item">
              <Nav className="nav-item" onClick={this.props.device == true ? this.props.toggle : () => { }}>
                <FontAwesomeIcon icon={faSliders} className="mx-2 pt-1" />
                Slider
              </Nav>
            </Link>
            <Link to='testimonials' className="link-item">
              <Nav className="nav-item" onClick={this.props.device == true ? this.props.toggle : () => { }}>
                <FontAwesomeIcon icon={faComment} className="mx-2 pt-1" />
                Testimonials
              </Nav>
            </Link>
            <Link to='about' className="link-item">
              <Nav className="nav-item" onClick={this.props.device == true ? this.props.toggle : () => { }}>
                <FontAwesomeIcon icon={faBriefcase} className="mx-2 pt-1" />
                About
              </Nav>
            </Link>
            <Link onClick={this.logout} className="link-item">
              <Nav className="nav-item" onClick={this.props.device == true ? this.props.toggle : () => { }}>
                <FontAwesomeIcon icon={faGear} className="mx-2 pt-1" />
                Log out
              </Nav>
            </Link>
          </Nav.Item>
        </Nav>
      </div>
    );
  }
}

export default SideBar;