import React, { useState, useEffect } from 'react';
import { faXmarkSquare, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import { deletSilderItem, getSlideritems } from "../../constants/AdminApis"
import { useNavigate } from 'react-router-dom';

export default function Slider() {
  const [getSliderData, setgetSliderData] = useState([]);
  const [delatestate,setdeleteState]= useState([])
  const userProfile = JSON.parse(localStorage.getItem('logindata'));
  let navigate =useNavigate();

  const handleDelete = async (e) => {
    let imgID = e.img_id;
    deletSilderItem(imgID,userProfile.access_token);
    setdeleteState(deletSilderItem())
    navigate('/admin/slider');
  }

  useEffect(() => {
    (async function () {
      try {
        let response = await getSlideritems(userProfile); // Wait for the promise to resolve
        // console.log("response-api",response)
        setgetSliderData(response.data);
      } catch (error) {
        // Handle errors if any
        console.error("Error fetching slider items:", error);
      }
    })()

  }, []);

  
  return (
    <>

      <div className='d-flex justify-content-end p-2'>
        <Link to="new-slider" className='btn btn-outline-primary'>New Slider</Link>
      </div>
      {
        getSliderData.length == 0 ? <div className='text-center'>No slider data found.</div> :
      <div className='table-wrapper '>
        <table className="table overflow-scroll">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Heading</th>
              <th scope="col">Description</th>
              <th scope="col">Image</th>
              <th scope="col">Edit</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          <tbody>
            {getSliderData.map((e, i) => {
              return (
                <tr  key={i}>
                  <th scope="row" >{i + 1}</th>
                  <td>{e.slider_heading}</td>
                  <td>{e.slider_description}</td>
                  <td><img src={`${e.slider_img_path}`} style={{ height: "100px", width: "100px" }} /></td>
                  {/* <td>{e.slider_img_path}</td> */}
                  <td><Link to="new-slider" state={{ sliderData: e }}><FontAwesomeIcon icon={faPenToSquare} className="lg-2 pt-1" style={{ color: "green", fontSize: "30px" }} /></Link></td>
                  <td onClick={() => handleDelete(e)} style={{ cursor: "pointer" }}><FontAwesomeIcon icon={faXmarkSquare} className="lg-2 pt-1" style={{ color: "red", fontSize: "35px" }} /></td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      }
    </>
  )
}
