import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../login/Login.css';
import { useNavigate, Link } from 'react-router-dom';
import { addUpdateAbout } from "../../constants/AdminApis"

export default function AddAbout() {
    let navigate = useNavigate();
    const [formVal, setformVal] = useState("");
    const [errors, setErrors] = useState({});
    const [aboutImg, setaboutImg] = useState(null);

    const location = useLocation();
    const { aboutData } = location.state || "";
    console.log("aboutData", aboutData)
    const userProfile = JSON.parse(localStorage.getItem('logindata'));

    const handleFileChnage = (e) => {
        setaboutImg(e.target.files[0])
    }
    const setField = (field, value) => {
        setformVal({
            ...formVal,
            [field]: value
        })
        if (!!errors[field])
            setErrors({
                ...errors,
                [field]: null
            })
    }
    const validateForm = () => {
        const { aboutus_heading, aboutus_subheading, aboutus_description, aboutus_img_path } = formVal
        const newErrors = {}
        if ((!aboutus_heading || aboutus_heading === '') && aboutData == undefined)
            newErrors.aboutus_heading = 'Please enter aboutus heading!!'

        if ((!aboutus_description || aboutus_description === '') && aboutData == undefined)
            newErrors.aboutus_description = 'Please enter aboutus discription!!'

        // if ((!aboutus_img_path || aboutus_img_path === '') && !aboutImg)
        //     newErrors.aboutus_img_path = 'Please upload aboutus image!!'
        return newErrors
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log(aboutImg)
        const formData = new FormData();
        if (aboutImg) {
            formData.append('aboutus_img_path', aboutImg)
        }

        let postData = { ...formVal, "updated_by": userProfile.username }
        postData = { ...postData, 'aboutus_img_path': aboutImg }



        e.preventDefault();
        const formErrors = validateForm()
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
        } else if (aboutData != undefined) {
            let putData = { ...formVal, "updated_by": userProfile.username, "a_id": aboutData.a_id }
            putData = { ...putData, 'aboutus_img_path': aboutImg }
            console.log("PutDAta :", putData)
            addUpdateAbout(putData, userProfile)
            navigate('/admin/about');
        } else {
            addUpdateAbout(postData, userProfile);
            navigate('/admin/about');
        }
    }
    return (
        <>
            <div className='container col-md-10'>
                <h2 className='text-center fw-bold mb-4'>Add About</h2>
                <form className="row g-3">
                    <div className="col-md-6">
                        <label className="form-label">About Heading</label>
                        <input type="text" className="form-control" id="aboutheading" value={aboutData == undefined ? formVal.aboutus_heading : formVal.aboutus_heading == "" ? formVal.aboutus_heading : formVal.aboutus_heading || aboutData.aboutus_heading} onChange={e => setField('aboutus_heading', e.target.value)} />
                        <label className='text-danger'>{errors.aboutus_heading}</label>
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Sub Heading</label>
                        <input type="text" className="form-control" id="aboutsubheading" value={aboutData == undefined ? formVal.aboutus_subheading : formVal.aboutus_subheading == "" ? formVal.aboutus_subheading : formVal.aboutus_subheading || aboutData.aboutus_subheading} onChange={e => setField('aboutus_subheading', e.target.value)} />
                        <label className='text-danger'>{errors.aboutus_subheading}</label>
                    </div>
                    <div className='col-md-12'>
                        <label className="form-label">Upload About Image</label>
                        <input className="form-control" type="file" id="aboutImg" name="aboutus_img_path" onChange={handleFileChnage} />
                        <label className='text-danger'>{errors.aboutus_img_path}</label>
                    </div>
                    <div>
                        <label className="form-label">Description <span className='hintLabel blink'> (Add <code>&lt;br&gt;</code> between for paragraph)</span></label>
                        <textarea className="form-control" id="aboutdiscription" rows="3" value={aboutData == undefined ? formVal.aboutus_description : formVal.aboutus_description == "" ? formVal.aboutus_description : formVal.aboutus_description || aboutData.aboutus_description} onChange={e => setField('aboutus_description', e.target.value)} />
                        <label className='text-danger'>{errors.aboutus_description}</label>
                    </div>
                    <div className="col-md-12">
                        <button type="submit" onClick={handleSubmit} className="btn btn-primary">{aboutData == undefined ? "Add About" : "Update About"}</button>
                        <Link to='/admin/about'>
                            <button type="submit" className="btn btn-primary btn-cancel">Cancel</button>
                        </Link>
                    </div>
                </form>
            </div>
        </>
    )
}
