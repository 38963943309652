import React, { useState, useEffect } from 'react';
import { addUpdateCategories, getCategoriesitems } from "../../constants/AdminApis";
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export default function AddNewCategroy() {
    const location = useLocation();
    const { cateData } = location.state || "";
// console.log("cateData",cateData)
    const [catList, setCatList] = useState([]);
    // Filtering the array based on cat_type
    let filteredCategories = catList.filter(catList => catList.cat_type === "Main");
    const [formVal, setformVal] = useState({
        is_home: 'N',
        is_active: 'Y',
        parent_catID: '0',
        cat_type: 'Main'
    });
    const [errors, setErrors] = useState({});

    const [catType, setCatType] = useState('Main')

    const navigate = useNavigate();
    const userProfile = JSON.parse(localStorage.getItem('logindata'));
    // console.log("catType", catType)
    useEffect(() => {
        if (cateData != undefined)
            setCatType(cateData.cat_type);
        (async function () {
            try {
                let response = await getCategoriesitems(userProfile); // Wait for the promise to resolve
                setCatList(response.data);
            } catch (error) {
                // Handle errors if any
                console.error("Error fetching slider items:", error);
            }
        })()
    }, []);

    const setField = (field, value) => {
        if (value === 'Main') {
            setCatType('Main');
        }
        if (value === 'Subcat') {
            setCatType('Subcat');
            setCatList(filteredCategories)
        }
        setformVal({
            ...formVal,
            [field]: value
        })
        if (!!errors[field])
            setErrors({
                ...errors,
                [field]: null
            })
    }

    const validateForm = () => {
        const { cat_name, cat_imgpath } = formVal
        const newErrors = {}
        if ((!cat_name || cat_name === '') && cateData == undefined)
            newErrors.cat_name = 'Please enter product name!'

        if ((!cat_imgpath || cat_imgpath === '') && cateData == undefined)
            newErrors.cat_imgpath = 'Please upload image!'

        return newErrors
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let postData = { ...formVal, "updated_by": userProfile.username };
        const formErrors = validateForm()
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
        } else if (cateData != undefined) {
            let putData = { ...postData, "cat_id": cateData.cat_id, "cat_type":catType }
            // console.log("putData.... :", putData)

            addUpdateCategories(putData, userProfile)
            navigate('/admin/categories');
        } else {
            addUpdateCategories(postData, userProfile)
            navigate('/admin/categories');
        }
    }
    return (
        <>

            <div className='container col-md-10'>
                <h2 className='text-center fw-bold mb-4'>Add New Categories</h2>
                <form className="row g-3">
                    <div className='col-md-6'>
                        <label className="form-label">Category Type</label>
                        <div className="input-group">
                            <select className="form-select" id="cat_type" aria-label="Example select with button addon" value={cateData == undefined ? catType : catType || cateData.cat_type} onChange={e => setField('cat_type', e.target.value)} >
                                <option value="Main">Main</option>
                                <option value="Subcat">Sub Cat</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Category Name</label>
                        <div className="input-group">
                            {/* <input type="text" className="form-control" id="cat_name" value={cateData == undefined ? formVal.cat_name : formVal.cat_name || cateData.cat_name} onChange={e => setField('cat_name', e.target.value)} /> */}
                            <input type="text" className="form-control" id="sliderheading" value={cateData == undefined ? formVal.cat_name : formVal.cat_name == "" ? formVal.cat_name : formVal.cat_name || cateData.cat_name} onChange={e => setField('cat_name', e.target.value)} />
                        </div>
                        <label className='text-danger'>{errors.cat_name}</label>
                    </div>

                    <div className='col-md-6'>
                        <label className="form-label">Category Image</label>
                        <div className="input-group">
                            <input className="form-control" type="file" id="cat_imgpath" name="slider_img_path" onChange={e => setField('cat_imgpath', e.target.files[0])} />
                        </div>
                        <label className='text-danger'>{errors.cat_imgpath}</label>
                    </div>
                    {catType === 'Main' ? <div className='col-md-6'>
                        <label className="form-label">Category Menu Image</label>
                        <div className="input-group">
                            <input className="form-control" type="file" id="catmenu_imgpath" name="slider_img_path" onChange={e => setField('catmenu_imgpath', e.target.files[0])} />
                            <label className='text-danger'></label>
                        </div>
                    </div> : null}
                    {catType === 'Subcat' ? <div className='col-md-6' >
                        <label className="form-label">Category Id</label>
                        <div className="input-group">
                            <select className="form-select" id="parent_catID" aria-label="Example select with button addon" onChange={e => setField('parent_catID', e.target.value)} value={cateData != undefined && formVal.parent_catID=="0" ? cateData.parent_catID : formVal.parent_catID} >
                                <option value="" selected>Choose...</option>
                                {filteredCategories.map((e, i) => {
                                    return (
                                        <option key={i} value={`${e.cat_id}`}>{e.cat_name}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div> : null}
                    <div className='col-md-4'>
                        <label className="form-label">Home Page</label>
                        <div className="input-group">
                            <select className="form-select" id="is_home" aria-label="Example select with button addon" value={cateData == undefined ? formVal.is_home : formVal.is_home || cateData.is_home} onChange={e => setField('is_home', e.target.value)} >
                                <option value="Y">Yes</option>
                                <option value="N" selected>No</option>
                            </select>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <label className="form-label">Active Status</label>
                        <div className="input-group">
                            <select className="form-select" id="is_active" aria-label="Example select with button addon" value={cateData == undefined ? formVal.is_active : formVal.is_active || cateData.is_active} onChange={e => setField('is_active', e.target.value)}>
                                <option value="Y" selected>Yes</option>
                                <option value="N">No</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <button type="submit" onClick={handleSubmit} className="btn btn-primary">{cateData == undefined ? "Add Category " : "Update Category"}</button>
                        <Link to='/admin/categories'>
                            <button type="submit" className="btn btn-primary btn-cancel">Cancel</button>
                        </Link>
                    </div>
                </form>
            </div>
        </>
    )
}
