import { Routes, Route } from 'react-router-dom';
import Login from './admin/components/login/Login';
import Dashboard from './admin/components/dashboard/Dashboard';
import Products from './admin/components/products/Products';
import Categories from './admin/components/categories/Categories';
import Slider from './admin/components/slider/Slider';
import Testimonials from './admin/components/testimonials/Testimonials';
import About from './admin/components/about/About';
import Settings from './admin/components/settings/Settings';
import AddNewSlider from './admin/components/slider/newslider/AddNewSlider';
import AddNewCategroy from "./admin/components/categories/AddNewCategroy"
import AddNewProducts from "./admin/components/products/AddNewProducts"
import DashboardMain from './admin/components/dashboard/DashboardMain';
import AddAbout from './admin/components/about/AddAbout';
import AddTestimonials from './admin/components/testimonials/AddTestimonials';
import { useState } from 'react';
import PrivateRoutes from './admin/components/navbar/ProtectedRoutes';
import './App.css'

function App() {
  const [logindata, setloginData] = useState("");
  // console.log("logindata",logindata)
  const loginCallback = (callback) => {
    // console.log("callback",callback);
    setloginData(callback.data);
  }
  return (
    <div className="App">
      <Routes basename="/admin">
        <Route element={<PrivateRoutes />} >
          <Route path='admin' element={<Dashboard />} >
            <Route index element={<DashboardMain />} />
            <Route path='products' element={<Products />} />
            <Route path='categories' element={<Categories />} />
            <Route path='slider' element={<Slider />} />
            <Route path='testimonials' element={<Testimonials />} />
            <Route path='testimonials/new-testimonials' element={<AddTestimonials />} />
            <Route path='about' element={<About />} />
            <Route path='about/new-about' element={<AddAbout />} />
            <Route path='settings' element={<Settings />} />
            <Route path='slider/new-slider' element={<AddNewSlider />} />
            <Route path='categories/new-category' element={<AddNewCategroy />} />
            <Route path='products/new-product' element={<AddNewProducts />} />
          </Route>
        </Route>
        <Route path='/' element={<Login callbackfun={loginCallback} />} />
      </Routes>
    </div>
  );
}
export default App;
