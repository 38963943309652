import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../login/Login.css';
import { useNavigate, Link } from 'react-router-dom';
import { addUpdateTestimonials } from "../../constants/AdminApis"

export default function AddTestimonials() {
    let navigate = useNavigate();
    const [formVal, setformVal] = useState("");
    const [errors, setErrors] = useState({});
    const [testimonialsImg, setTestimonialsImg] = useState(null);

    const location = useLocation();
    const { testimonialsData } = location.state || "";
    console.log("testimonialsData", testimonialsData)
    const userProfile = JSON.parse(localStorage.getItem('logindata'));

    const handleFileChnage = (e) => {
        setTestimonialsImg(e.target.files[0])
    }
    const setField = (field, value) => {
        setformVal({
            ...formVal,
            [field]: value
        })
        if (!!errors[field])
            setErrors({
                ...errors,
                [field]: null
            })
    }
    const validateForm = () => {
        const { t_username, t_title, t_description, t_img } = formVal
        const newErrors = {}
        if ((!t_username || t_username === '') && testimonialsData == undefined)
            newErrors.t_username = 'Please enter username!!'

        if ((!t_title || t_title === '') && testimonialsData == undefined)
            newErrors.t_title = 'Please enter testimonials title!!'

        if ((!t_description || t_description === '') && testimonialsData == undefined)
            newErrors.t_description = 'Please enter testimonials discription!!'

        // if ((!aboutus_img_path || aboutus_img_path === '') && !aboutImg)
        //     newErrors.aboutus_img_path = 'Please upload aboutus image!!'
        return newErrors
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log(testimonialsImg)
        const formData = new FormData();
        if (testimonialsImg) {
            formData.append('t_img', testimonialsImg)
        }

        let postData = { ...formVal, "updated_by": userProfile.username }
        postData = { ...postData, 't_img': testimonialsImg }



        e.preventDefault();
        const formErrors = validateForm()
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
        } else if (testimonialsData != undefined) {
            let putData = { ...formVal, "updated_by": userProfile.username, "t_id": testimonialsData.t_id }
            putData = { ...putData, 't_img': testimonialsImg }
            console.log("PutDAta :", putData)
            addUpdateTestimonials(putData, userProfile)
            navigate('/admin/testimonials');
        } else {
            addUpdateTestimonials(postData, userProfile);
            navigate('/admin/testimonials');
        }
    }
    return (
        <>
            <div className='container col-md-10'>
                <h2 className='text-center fw-bold mb-4'>Add Testimonials</h2>
                <form className="row g-3">
                    <div className="col-md-6">
                        <label className="form-label">Testimonials username</label>
                        <input type="text" className="form-control" id="theading" value={testimonialsData == undefined ? formVal.t_username : formVal.t_username == "" ? formVal.t_username : formVal.t_username || testimonialsData.t_username} onChange={e => setField('t_username', e.target.value)} />
                        <label className='text-danger'>{errors.t_username}</label>
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Testimonials Title</label>
                        <input type="text" className="form-control" id="tTitle" value={testimonialsData == undefined ? formVal.t_title : formVal.t_title == "" ? formVal.t_title : formVal.t_title || testimonialsData.t_title} onChange={e => setField('t_title', e.target.value)} />
                        <label className='text-danger'>{errors.t_title}</label>
                    </div>
                    <div className='col-md-12'>
                        <label className="form-label">Upload Testimonials Image</label>
                        <input className="form-control" type="file" id="tImg" name="t_img" onChange={handleFileChnage} />
                        <label className='text-danger'>{errors.t_img}</label>
                    </div>
                    <div>
                        <label className="form-label">Testimonials Description</label>
                        <textarea className="form-control" id="tdiscription" rows="3" value={testimonialsData == undefined ? formVal.t_description : formVal.t_description == "" ? formVal.t_description : formVal.t_description || testimonialsData.t_description} onChange={e => setField('t_description', e.target.value)} />
                        <label className='text-danger'>{errors.t_description}</label>
                    </div>
                    <div className="col-md-12">
                        <button type="submit" onClick={handleSubmit} className="btn btn-primary">{testimonialsData == undefined ? "Add Testimonials" : "Update Testimonials"}</button>
                        <Link to='/admin/testimonials'>
                            <button type="submit" className="btn btn-primary btn-cancel">Cancel</button>
                        </Link>
                    </div>
                </form>
            </div>
        </>
    )
}
