import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../../login/Login.css';
import { useNavigate,Link } from 'react-router-dom';
import {addUpdateSlider} from "../../../constants/AdminApis"

export default function AddNewSlider() {
    let navigate =useNavigate();
    const [formVal, setformVal] = useState("");
    const [errors, setErrors] = useState({});
    const [sliderImg, setSliderImg] = useState(null);

    const location = useLocation();
    const { sliderData } = location.state||"";

    const userProfile = JSON.parse(localStorage.getItem('logindata'));

    const handleFileChnage =(e) =>{
        setSliderImg(e.target.files[0])
    }
    const setField = (field, value) => {
        setformVal({
            ...formVal,
            [field]: value
        })
        if (!!errors[field])
            setErrors({
                ...errors,
                [field]: null
            })
    }
    const validateForm = () => {
        const { slider_heading, slider_description, slider_img_path } = formVal
        const newErrors = {}
        if ((!slider_heading || slider_heading === '' ) && sliderData==undefined)
            newErrors.slider_heading = 'Please enter slider heading!!'

        if ((!slider_description || slider_description === '') && sliderData==undefined)
            newErrors.slider_description = 'Please enter slider discription!!'

        if ((!slider_img_path || slider_img_path === '') && !sliderImg)
            newErrors.slider_img_path = 'Please upload slider image!!'
        return newErrors
    }
    const handleSubmit = async (e) => {
        e.preventDefault() 
        console.log(sliderImg)
        const formData = new FormData();
        if(sliderImg){
            formData.append('slider_img_path',sliderImg)
        }

        let postData = { ...formVal, "updated_by": userProfile.username }
        postData = { ...postData, 'slider_img_path':sliderImg }

       

        e.preventDefault();
        const formErrors = validateForm()
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
        } else if(sliderData!=undefined){
            let putData = { ...formVal, "updated_by": userProfile.username, "img_id":sliderData.img_id }
            putData = { ...putData, 'slider_img_path':sliderImg }
            console.log("PutDAta :",putData)
            addUpdateSlider(putData,userProfile)
            navigate('/admin/slider');
        }else{
            addUpdateSlider(postData,userProfile);
            navigate('/admin/slider');
        }
    }
    return (
        <>
            <div className='container col-md-10'>
                <h2 className='text-center fw-bold mb-4'>Add New Slider</h2>
                <form className="row g-3">
                    <div className="col-md-6">
                        <label className="form-label">Slider Heading</label>
                        {/* <input type="text" className="form-control" id="sliderheading" value={sliderData == undefined ? formVal.slider_heading : formVal.slider_heading || sliderData.slider_heading} onChange={e => setField('slider_heading',e.target.value)} /> */}
                        <input type="text" className="form-control" id="sliderheading" value={sliderData == undefined ? formVal.slider_heading : formVal.slider_heading==""? formVal.slider_heading:formVal.slider_heading || sliderData.slider_heading} onChange={e => setField('slider_heading',e.target.value)} />
                        <label className='text-danger'>{errors.slider_heading}</label>
                    </div>
                    <div className='col-md-6'>
                        <label className="form-label">Upload Slider Image</label>
                        <input className="form-control" type="file" id="sliderimg" name="slider_img_path" onChange={handleFileChnage} />
                        <label className='text-danger'>{errors.slider_img_path}</label>
                    </div>
                    <div>
                        <label className="form-label">Description</label>
                        {/* <textarea className="form-control" id="sliderdiscription" rows="3" value={sliderData == undefined ? formVal.slider_description : formVal.slider_description||sliderData.slider_description} onChange={e => setField('slider_description', e.target.value)} /> */}
                        <textarea className="form-control" id="sliderdiscription" rows="3" value={sliderData == undefined ? formVal.slider_description : formVal.slider_description=="" ? formVal.slider_description:formVal.slider_description||sliderData.slider_description} onChange={e => setField('slider_description', e.target.value)} />
                        <label className='text-danger'>{errors.slider_description}</label>
                    </div>
                    <div className="col-md-12">
                        <button type="submit" onClick={handleSubmit} className="btn btn-primary">{sliderData==undefined ? "Add Slider":"Update Slider"}</button>
                        <Link to='/admin/slider'>
                            <button type="submit"  className="btn btn-primary btn-cancel">Cancel</button>
                        </Link>
                    </div>
                </form>
            </div>
        </>
    )
}
