import React, { useState, useEffect,useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { addUpdateProduct, getCategoriesitems } from '../../constants/AdminApis';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkSquare } from "@fortawesome/free-solid-svg-icons";
export default function AddNewProducts() {
    let navigate = useNavigate();
    const [catList, setCatList] = useState([]);
    const [formVal, setformVal] = useState({
        avalibility_stock: 'In Stock',
        is_home: 'N',
        is_bestsale: 'N',
        is_flashsale: 'N',
        is_active: 'Y',
        sale_price: 0,
        rating: 0,
    });
    const [errors, setErrors] = useState({});
    const userProfile = JSON.parse(localStorage.getItem('logindata'));
    const location = useLocation();
    const { productData } = location.state || "";
   

    useEffect(() => {
        (async function () {
            try {
                let response = await getCategoriesitems(userProfile); // Wait for the promise to resolve
                setCatList(response.data);
                if(productData != undefined){
                    setWeightBoxes(JSON.parse(productData.weight))
                    setFlavorBoxes(JSON.parse(productData.flavor))
                    console.log("Weight :",weightBoxes)
                    console.log("Flavor :",flavorBoxes)

               }
            } catch (error) {
                // Handle errors if any
                console.error("Error fetching slider items:", error);
            }
        })()
    }, []);

    const setField = (field, value) => {
        setformVal({
            ...formVal,
            [field]: value
        })
        if (!!errors[field])
            setErrors({
                ...errors,
                [field]: null
            })
    }

    const validateForm = () => {
        const { p_name, maincat_ID, orginal_price, p_imgpath } = formVal
        const newErrors = {}
        if ((!p_name || p_name === '') && productData == undefined)
            newErrors.p_name = 'Please enter product name!'

        if ((!orginal_price || orginal_price === '') && productData == undefined)
            newErrors.orginal_price = 'Please enter product price!'

        if ((!maincat_ID || maincat_ID === '') && productData == undefined)
            newErrors.maincat_ID = 'Please select category ID!'

        if ((!p_imgpath || p_imgpath === '') && productData == undefined)
            newErrors.p_imgpath = 'Please upload image!'

        return newErrors
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const weightValues = [];
        const flavorValues = [];
    
        // Iterate through weight inputs
        // let fileData=''
        for (let i = 0; i < weightBoxes.length; i++) {
            const weightInput = document.getElementById(`weight_${i}`);
            if (weightInput) {
                weightValues.push({ name: weightInput.name, wValue: weightInput.value });
            }
        }
        for (let i = 0; i < flavorBoxes.length; i++) {
            const flavorInput = document.getElementById(`flavor_${i}`);
            if (flavorInput) {
                flavorValues.push({ name: flavorInput.name, fValue: flavorInput.value });
            }
        }
        // Log or use the collected values
        console.log("Weight Values:", weightValues);
        console.log("Flavor Values:", flavorValues);
        let postData = { ...formVal, "updated_by": userProfile.username,'weightValues':weightValues,'flavorValues':flavorValues }
        const formErrors = validateForm()
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
        } else if (productData != undefined) {
            let putData = { ...postData, "p_id": productData.p_id }
            // console.log('putData - ', postData)
            addUpdateProduct(putData, userProfile)
            navigate('/admin/products');
        } else {
            // console.log('postData - ', postData)
            addUpdateProduct(postData, userProfile)
            navigate('/admin/products');
        }
    }
                   
   /* Weight Data */
    const [weightBoxes, setWeightBoxes] = useState([]);
    function deleteWeightTask(id) {
        setWeightBoxes(weightBoxes.filter(weight => weight.name !== id));
    }
    const addWeightBox = (e) => {
        e.preventDefault();
        setWeightBoxes([...weightBoxes, { name: `weight_${Math.floor(Math.random() * (100 - 1 + 1)) + 1}` }]);
    };
  
    /* Flavor Data */
    const [flavorBoxes, setFlavorBoxes] = useState([]);
    function deleteFlavorTask(id) {
        setFlavorBoxes(flavorBoxes.filter(flavor => flavor.name !== id));
    }
    const addFlavorBox = (e) => {
        e.preventDefault();
        setFlavorBoxes([...flavorBoxes, { name: `flavor_${Math.floor(Math.random() * (100 - 1 + 1)) + 1}` }]);
    };
    
    return (
        <>
            <div className='container col-md-10'>
                <h2 className='text-center fw-bold mb-4'>Add New Product</h2>
                <form className="row g-3">
                    <div className='col-md-6'>
                        <label className="form-label">Product Name</label>
                        <div className="input-group">
                            <input type="text" className="form-control" id="p_name" value={productData == undefined ? formVal.p_name : formVal.p_name == "" ? formVal.p_name : formVal.p_name || productData.p_name} onChange={e => setField('p_name', e.target.value)} />
                        </div>
                        <label className='text-danger'>{errors.p_name}</label>
                    </div>
                    <div className='col-md-6'>
                        <label className="form-label">Product Image</label>
                        <div className="input-group">
                            <input className="form-control" type="file" id="p_imgpath" name="p_imgpath" onChange={e => setField('p_imgpath', e.target.files[0])} />
                        </div>
                        <label className='text-danger'>{errors.p_imgpath}</label>
                    </div>
                    <div className='col-md-4'>
                        <label className="form-label">Rating</label>
                        <div className="input-group">
                            <input type="text" className="form-control" id="rating" value={productData == undefined ? formVal.rating : formVal.rating=="" ? formVal.rating:formVal.rating || productData.rating} onChange={e => setField('rating', e.target.value)} />
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <label className="form-label">Original Price</label>
                        <div className="input-group">
                            <input type="text" className="form-control" id="orginal_price" value={productData == undefined ? formVal.orginal_price : formVal.orginal_price=="" ? formVal.orginal_price:formVal.orginal_price || productData.orginal_price} onChange={e => setField('orginal_price', e.target.value)} />
                        </div>
                        <label className='text-danger'>{errors.orginal_price}</label>
                    </div>
                    <div className='col-md-4'>
                        <label className="form-label">Sale Price</label>
                        <div className="input-group">
                            <input type="text" className="form-control" id="sale_price" value={productData == undefined ? formVal.sale_price : formVal.sale_price=="" ? formVal.sale_price:formVal.sale_price || productData.sale_price} onChange={e => setField('sale_price', e.target.value)} />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <label className="form-label">Description</label>
                        <textarea className="form-control" id="p_desc" rows="2" value={productData == undefined ? formVal.p_desc : formVal.p_desc=="" ? formVal.p_desc:formVal.p_desc || productData.p_desc} onChange={e => setField('p_desc', e.target.value)} />
                        <label className='text-danger'>{errors.p_desc}</label>
                    </div>
                    <div className='col-md-6'>
                        <label className="form-label">Offers</label>
                        <span className='suggestion'>[ eg. A,B,C ]</span>
                        <div className="input-group">
                            <textarea className="form-control" id="offers" rows="2" value={productData == undefined ? formVal.offers : formVal.offers=="" ? formVal.offers:formVal.offers || productData.offers} onChange={e => setField('offers', e.target.value)} placeholder='Enter multiple value comma seprated' />
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <label className="form-label">Category ID</label>
                        <select className="form-select" id="maincat_ID" aria-label="Example select with button addon" value={productData == undefined ? formVal.maincat_ID : formVal.maincat_ID || productData.maincat_ID} onChange={e => setField('maincat_ID', e.target.value)}>
                            <option value="" selected>Choose...</option>
                            {catList.map((e, i) => {
                                return (
                                    <option key={i} value={`${e.cat_id}`}>{e.cat_name}</option>
                                )
                            })}
                        </select>
                        <label className='text-danger'>{errors.maincat_ID}</label>
                    </div>
                    <div className='col-md-4'>
                        <label className="form-label">Home Page</label>
                        <div className="input-group">
                            <select className="form-select" id="is_home" aria-label="Example select with button addon" value={productData == undefined ? formVal.is_home : formVal.is_home || productData.is_home} onChange={e => setField('is_home', e.target.value)} >
                                <option value="Y">Yes</option>
                                <option value="N" selected>No</option>
                            </select>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <label className="form-label">Best Sale</label>
                        <div className="input-group">
                            <select className="form-select" id="is_bestsale" aria-label="Example select with button addon" value={productData == undefined ? formVal.is_bestsale : formVal.is_bestsale || productData.is_bestsale} onChange={e => setField('is_bestsale', e.target.value)} >
                                <option value="Y">Yes</option>
                                <option value="N" selected>No</option>
                            </select>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <label className="form-label">Flash Sale</label>
                        <div className="input-group">
                            <select className="form-select" id="is_flashsale" aria-label="Example select with button addon" value={productData == undefined ? formVal.is_flashsale : formVal.is_flashsale || productData.is_flashsale} onChange={e => setField('is_flashsale', e.target.value)} >
                                <option value="Y">Yes</option>
                                <option value="N" selected>No</option>
                            </select>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <label className="form-label">Avalibility Stock</label>
                        <div className="input-group">
                            <select className="form-select" id="avalibility_stock" aria-label="Example select with button addon" value={productData == undefined ? formVal.avalibility_stock : formVal.avalibility_stock || productData.avalibility_stock} onChange={e => setField('avalibility_stock', e.target.value)} >
                                <option value="In Stock" >In Stock</option>
                                <option value="Sold Out">Sold Out</option>
                            </select>
                        </div>
                        <label className='text-danger'>{errors.avalibility_stock}</label>
                    </div>
                    <div className='col-md-4'>
                        <label className="form-label">Active Status</label>
                        <div className="input-group">
                            <select className="form-select" id="is_active" aria-label="Example select with button addon" value={productData == undefined ? formVal.is_active : formVal.is_active || productData.is_active} onChange={e => setField('is_active', e.target.value)}>
                                <option value="Y" selected>Yes</option>
                                <option value="N">No</option>
                            </select>
                        </div>
                    </div>
                    {/* Weight UI */}
                    <hr className='divider'/>
                    <div className='row col-md-12'>
                      
                        {weightBoxes.map((value, index) => (
                        <div key={index} className='row'>
                            <div className='col-md-5'>
                                <label className="form-label">Weight</label>
                                <div className="input-group">
                                    <input type="text" value={ value.value == undefined ? value.wValue : value.value} className="form-control"  id={`weight_${index}`} placeholder='' name={`weight_${index}`} />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <label className="form-label">Upload Image</label>
                                <div className="input-group">
                                    <input className="form-control" type="file"   id={`w_imgpath_${index}`} name={`wImg_${index}`}  onChange={e => setField(`wImg_${index}`, e.target.files[0])} />
                                </div>
                            </div>
                            <div className='col-md-1 wcloseIcon'>
                                <div className="input-group">
                                    <span className='list-icon' onClick={() => deleteWeightTask(value.name)}>
                                        <FontAwesomeIcon icon={faXmarkSquare} className="lg-2 pt-1" style={{ color: "red", fontSize: "35px",marginLeft:'15px' }} />
                                    </span>
                                </div>
                            </div>
                        </div>
                        ))}
                        <div className='col-md-12'>
                            <label className="form-label">&nbsp;</label>
                            <div className="input-group">
                                <button  onClick={addWeightBox} className="btn btn-primary">Add Weight </button>
                            </div>
                        </div>
                    </div>
                     {/* Flavor UI */}
                     <hr className='divider'/>
                    <div className='row col-md-12'>
                       
                        {flavorBoxes.map((value, index) => (
                        <div key={index} className='row'>
                            <div className='col-md-5'>
                                <label className="form-label">Flavor</label>
                                <div className="input-group">
                                    <input type="text" value={ value.value == undefined ? value.fValue : value.value} className="form-control"  id={`flavor_${index}`} placeholder='' name={`flavor_${index}`} />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <label className="form-label">Upload Image</label>
                                <div className="input-group">
                                    <input className="form-control" type="file"   id={`f_imgpath_${index}`} name={`fImg_${index}`}  onChange={e => setField(`fImg_${index}`, e.target.files[0])} />
                                </div>
                            </div>
                            <div className='col-md-1 fcloseIcon'>
                                <div className="input-group">
                                    <span className='list-icon' onClick={() => deleteFlavorTask(value.name)}>
                                        <FontAwesomeIcon icon={faXmarkSquare} className="lg-2 pt-1" style={{ color: "red", fontSize: "35px",marginLeft:'15px' }} />
                                    </span>
                                </div>
                            </div>
                        </div>
                        ))}
                        <div className='col-md-12'>
                            <label className="form-label">&nbsp;</label>
                            <div className="input-group">
                                <button  onClick={addFlavorBox} className="btn btn-primary">Add Flavor </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <button type="submit" onClick={handleSubmit} className="btn btn-primary">{productData == undefined ? "Add Product " : "Update Product"} </button>
                        <Link to='/admin/products'>
                            <button type="submit" className="btn btn-primary btn-cancel">Cancel</button>
                        </Link>
                    </div>
                </form>
            </div>
        </>
    )
}
