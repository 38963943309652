import React, { useState, useEffect } from 'react';
import { faXmarkSquare, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import { deletProduct, getProducts } from "../../constants/AdminApis"
import { useNavigate } from 'react-router-dom';

export default function Products() {
  const [productData,setProductData] = useState([]);
  const [delatestate,setdeleteState]= useState([]);
  
  const userProfile = JSON.parse(localStorage.getItem('logindata'));
  let navigate =useNavigate();
  const handleDelete = async (e) => {
    let productID = e.p_id;
    deletProduct(productID,userProfile.access_token);
    setdeleteState(deletProduct())
    navigate('/admin/products');
  }

  useEffect(() => {
    (async function () {
      try {
        let response = await getProducts(userProfile); // Wait for the promise to resolve
        setProductData(response.data);
      } catch (error) {
        console.error("Error fetching product items:", error.message);
        // alert(error.message)
      }
    })()

  }, []);

  
  return (
    <>

      <div className='d-flex justify-content-end p-2'>
        <Link to="new-product" className='btn btn-outline-primary'>New Product</Link>
      </div>
      {
        productData.length == 0 ? <div className='text-center'>No product found.</div> :
      <div className='table-wrapper '>
        <table className="table overflow-scroll w-100">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col"> Name</th>
              <th scope="col">Category</th>
              <th scope="col">Stock</th>
              <th scope="col">Image</th>
              <th scope="col">Edit</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          <tbody>
            {productData.map((e, i) => {
              return (
                <tr  key={i}>
                  <th scope="row" >{i + 1}</th>
                  <td>{e.p_name}</td>
                  <td>{e.cat_name}</td>
                  <td>{e.avalibility_stock}</td>
                  <td><img src={`${e.p_imgpath}`} style={{ height: "100px", width: "100px" }} /></td>
                  <td><Link to="new-product" state={{ productData: e }}><FontAwesomeIcon icon={faPenToSquare} className="lg-2 pt-1" style={{ color: "green", fontSize: "30px" }} /></Link></td>
                  <td onClick={() => handleDelete(e)} style={{ cursor: "pointer" }}><FontAwesomeIcon icon={faXmarkSquare} className="lg-2 pt-1" style={{ color: "red", fontSize: "35px" }} /></td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      }
    </>
  )
}
