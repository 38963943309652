import './Login.css';
import { Button, Container } from 'react-bootstrap';
import logo from '../../../bn-logo.jpg'
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Navigate } from "react-router-dom";
import {getAdminLogin} from "../../constants/AdminApis"

function Login(props) {
  const [loginAuth, setLoginAuth] = useState(false);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const userProfile = JSON.parse(localStorage.getItem('logindata'));

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value
    })
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null
      })
  }
  const validateForm = () => {
    const { username, password } = form
    const newErrors = {}
    if (!username || username === '')
      newErrors.username = 'Please enter your username'

    if (!password || password === '')
      newErrors.password = 'Please enter your password'
    return newErrors
  }
  
  const authonticateLogin = (e) => {
    e.preventDefault();

    const formErrors = validateForm()
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors)
    }
    else
    getAdminLogin(form, props.callbackfun)
  }
  useEffect(() => {
    if (userProfile !== null && userProfile !== "") {
      setLoginAuth(true);
    }else{
      setLoginAuth(false)
    }
  }, [userProfile]);
  return (
    <>
      {userProfile !==""&& userProfile!==null  && (
        <>
          <Navigate to='/admin' />
        </>
      )}
      <div className='login-wrapper'>
        <Container className='d-grid h-100' id="login-container">
          <Form id="sign-in-form" className='text-center w-100'>
            <Link to='/'>
              <img src={logo} className="bigshot-logo mb-4" alt="logo" />
            </Link>
            <h1 className='fs-3 mb-3'>Please sign in</h1>
            <Form.Group className='m-2' controlId='sign-in-email'>
              <Form.Control type='username' size='lg' placeholder='Enter username' autoComplete='username' className='position-relative' onChange={e => setField('username', e.target.value)}
                value={form.username}
                isInvalid={errors.username}
              />
              <Form.Control.Feedback type='invalid' className='mb-3'>
                {errors.username}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mb-3 m-2' controlId='sign-in-password'>
              <Form.Control type='password' size='lg' placeholder='Password' autoComplete='password' className='position-relative' onChange={e => setField('password', e.target.value)}
                value={form.password}
                isInvalid={errors.password} />
              <Form.Control.Feedback type='invalid' className='mb3'>
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='d-flex justify-content-center mb-4' controlId='remember-me'>
              <Form.Check label="Remember me" />
            </Form.Group>
            <Link onClick={authonticateLogin}>
              <div className='d-grid'>
                <Button variant="primary" size="lg" >
                  Sign in
                </Button>
              </div>
            </Link>
            <p className='mt-5 text-muted'>&copy; 2023-2024</p>
          </Form>
        </Container>
      </div>
    </>
  );
}

export default Login;
