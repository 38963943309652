import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons";
import { Navbar, Button, Nav } from "react-bootstrap";
import {
  faBell,
  faList,
  faCircleUser,
  faRightFromBracket
} from "@fortawesome/free-solid-svg-icons";
class NavBar extends React.Component {
  render() {
    const userProfile = JSON.parse(localStorage.getItem('logindata'));
    return (
      <>
        <Navbar
          bg="light"
          className="navbar shadow-sm p-3 mb-5 bg-white rounded"
          expand
        >
          <Button variant="outline-primary" onClick={this.props.toggle}>
            <FontAwesomeIcon icon={faAlignLeft} />
          </Button>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" style={{ justifyContent: "right" }}>
            <Nav className="ml-auto" navbar>
              {/* <Nav.Link href="#"><FontAwesomeIcon icon={faBell} size="lg"/></Nav.Link>
            <Nav.Link href="#"><FontAwesomeIcon icon={faList} size="lg"/></Nav.Link> */}
              <Nav.Link href="#"><FontAwesomeIcon icon={faCircleUser} size="lg" /> {userProfile.username}</Nav.Link>
              {/* <Nav.Link href="#"><FontAwesomeIcon icon={faRightFromBracket} size="lg"/></Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </>
    );
  }
}

export default NavBar;

