import React from "react";
import "./dashboard.css";
import SideBar from '../sidebar/SideBar';
import NavbarMain from "../navbar/NavbarMain";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    // Moblie
    this.state = {
      isOpen: false,
      isMobile: true,
      mobileView:"",
    };

    this.previousWidth = -1;
  }

  updateWidth() {
    const width = window.innerWidth;
    const widthLimit = 576;
    const isMobile = width <= widthLimit;
    const wasMobile = this.previousWidth <= widthLimit;
    this.setState({mobileView:isMobile})
    if (isMobile !== wasMobile) {
      this.setState({
        isOpen: isMobile
      });
    }

    this.previousWidth = width;
  }

  componentDidMount() {
    this.updateWidth();
    window.addEventListener("resize", this.updateWidth.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth.bind(this));
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    // console.log("mobileView",this.state.mobileView);
    return (
      <>
        <div className="dashboard wrapper">
          <SideBar toggle={this.toggle} isOpen={this.state.isOpen} device={this.state.mobileView} />
          <NavbarMain toggle={this.toggle} isOpen={this.state.isOpen} />
        </div>
      </>
      
    );
  }
}

export default Dashboard;